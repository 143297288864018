
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.about-facilities-2-picture img {
	width: 782px;
}

.ready-to-load-img .about-facilities-2-picture-background, .ready-to-load-img .about-facilities-2-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/about-facilities-2.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/about-facilities-2.webp");
	}
	@media (max-width: 391px) {

		background-image: url("@{cdn0}/img/about-facilities-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/about-facilities-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/about-facilities-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/about-facilities-2-2x.webp");
		}
		
		@media (max-width: 391px) {

			background-image: url("@{cdn0}/img/about-facilities-2.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/about-facilities-2.webp");
			}
		}
	}
}

.about-facilities-2-picture-background-ratio, .about-facilities-2-picture-background-with-ratio {
	.ratio(0.4820971867007673);
	background-position: center center;
	background-size: cover;
}

.about-facilities-picture img {
	width: 1920px;
}

.ready-to-load-img .about-facilities-picture-background, .ready-to-load-img .about-facilities-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/about-facilities.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/about-facilities.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/about-facilities-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/about-facilities-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/about-facilities-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/about-facilities-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/about-facilities.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/about-facilities.webp");
			}
		}
	}
}

.about-facilities-picture-background-ratio, .about-facilities-picture-background-with-ratio {
	.ratio(0.19635416666666666);
	background-position: center center;
	background-size: cover;
}

.about-header-picture img {
	width: 1920px;
}

.ready-to-load-img .about-header-picture-background, .ready-to-load-img .about-header-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/about-header.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/about-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/about-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/about-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/about-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/about-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/about-header.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/about-header.webp");
			}
		}
	}
}

.about-header-picture-background-ratio, .about-header-picture-background-with-ratio {
	.ratio(0.221875);
	background-position: center center;
	background-size: cover;
}

.about-need-inset-picture img {
	width: 788px;
}

.ready-to-load-img .about-need-inset-picture-background, .ready-to-load-img .about-need-inset-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/about-need-inset.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/about-need-inset.webp");
	}
	@media (max-width: 394px) {

		background-image: url("@{cdn3}/img/about-need-inset-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/about-need-inset-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/about-need-inset-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/about-need-inset-2x.webp");
		}
		
		@media (max-width: 394px) {

			background-image: url("@{cdn3}/img/about-need-inset.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/about-need-inset.webp");
			}
		}
	}
}

.about-need-inset-picture-background-ratio, .about-need-inset-picture-background-with-ratio {
	.ratio(0.5520304568527918);
	background-position: center center;
	background-size: cover;
}

.all-craft-picture img {
	width: 178px;
}

.ready-to-load-img .all-craft-picture-background, .ready-to-load-img .all-craft-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/all-craft.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/all-craft.webp");
	}
	@media (max-width: 89px) {

		background-image: url("@{cdn0}/img/all-craft-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/all-craft-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/all-craft-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/all-craft-2x.webp");
		}
		
		@media (max-width: 89px) {

			background-image: url("@{cdn0}/img/all-craft.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/all-craft.webp");
			}
		}
	}
}

.all-craft-picture-background-ratio, .all-craft-picture-background-with-ratio {
	.ratio(0.9943820224719101);
	background-position: center center;
	background-size: cover;
}

.banquet-tile-picture img {
	width: 285.5px;
}

.ready-to-load-img .banquet-tile-picture-background, .ready-to-load-img .banquet-tile-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/banquet-tile.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/banquet-tile.webp");
	}
	@media (max-width: 142.75px) {

		background-image: url("@{cdn1}/img/banquet-tile-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/banquet-tile-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/banquet-tile-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/banquet-tile-2x.webp");
		}
		
		@media (max-width: 142.75px) {

			background-image: url("@{cdn1}/img/banquet-tile.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/banquet-tile.webp");
			}
		}
	}
}

.banquet-tile-picture-background-ratio, .banquet-tile-picture-background-with-ratio {
	.ratio(0.415061295971979);
	background-position: center center;
	background-size: cover;
}

.chevron-red-down-picture img {
	width: 17px;
}

.ready-to-load-img .chevron-red-down-picture-background, .ready-to-load-img .chevron-red-down-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/chevron-red-down.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/chevron-red-down.webp");
	}
	@media (max-width: 8.5px) {

		background-image: url("@{cdn2}/img/chevron-red-down-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/chevron-red-down-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/chevron-red-down-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/chevron-red-down-2x.webp");
		}
		
		@media (max-width: 8.5px) {

			background-image: url("@{cdn2}/img/chevron-red-down.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/chevron-red-down.webp");
			}
		}
	}
}

.chevron-red-down-picture-background-ratio, .chevron-red-down-picture-background-with-ratio {
	.ratio(0.6470588235294118);
	background-position: center center;
	background-size: cover;
}

.city-of-rochester-picture img {
	width: 256px;
}

.ready-to-load-img .city-of-rochester-picture-background, .ready-to-load-img .city-of-rochester-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/city-of-rochester.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/city-of-rochester.webp");
	}
	@media (max-width: 128px) {

		background-image: url("@{cdn3}/img/city-of-rochester-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/city-of-rochester-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/city-of-rochester-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/city-of-rochester-2x.webp");
		}
		
		@media (max-width: 128px) {

			background-image: url("@{cdn3}/img/city-of-rochester.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/city-of-rochester.webp");
			}
		}
	}
}

.city-of-rochester-picture-background-ratio, .city-of-rochester-picture-background-with-ratio {
	.ratio(0.29296875);
	background-position: center center;
	background-size: cover;
}

.competes-left-picture img {
	width: 1204px;
}

.ready-to-load-img .competes-left-picture-background, .ready-to-load-img .competes-left-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/competes-left.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/competes-left.webp");
	}
	@media (max-width: 602px) {

		background-image: url("@{cdn0}/img/competes-left-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/competes-left-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/competes-left-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/competes-left-2x.webp");
		}
		
		@media (max-width: 602px) {

			background-image: url("@{cdn0}/img/competes-left.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/competes-left.webp");
			}
		}
	}
}

.competes-left-picture-background-ratio, .competes-left-picture-background-with-ratio {
	.ratio(0.329734219269103);
	background-position: center center;
	background-size: cover;
}

.developers-welcome-picture img {
	width: 1920px;
}

.ready-to-load-img .developers-welcome-picture-background, .ready-to-load-img .developers-welcome-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/developers-welcome.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/developers-welcome.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/developers-welcome-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/developers-welcome-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/developers-welcome-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/developers-welcome-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/developers-welcome.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/developers-welcome.webp");
			}
		}
	}
}

.developers-welcome-picture-background-ratio, .developers-welcome-picture-background-with-ratio {
	.ratio(0.14739583333333334);
	background-position: center center;
	background-size: cover;
}

.dmc-picture img {
	width: 241px;
}

.ready-to-load-img .dmc-picture-background, .ready-to-load-img .dmc-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/dmc.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/dmc.webp");
	}
	@media (max-width: 120.5px) {

		background-image: url("@{cdn2}/img/dmc-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/dmc-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/dmc-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/dmc-2x.webp");
		}
		
		@media (max-width: 120.5px) {

			background-image: url("@{cdn2}/img/dmc.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/dmc.webp");
			}
		}
	}
}

.dmc-picture-background-ratio, .dmc-picture-background-with-ratio {
	.ratio(0.29045643153526973);
	background-position: center center;
	background-size: cover;
}

.donors-donation-spotlight-inset-picture img {
	width: 926px;
}

.ready-to-load-img .donors-donation-spotlight-inset-picture-background, .ready-to-load-img .donors-donation-spotlight-inset-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/donors-donation-spotlight-inset.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/donors-donation-spotlight-inset.webp");
	}
	@media (max-width: 463px) {

		background-image: url("@{cdn3}/img/donors-donation-spotlight-inset-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/donors-donation-spotlight-inset-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/donors-donation-spotlight-inset-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/donors-donation-spotlight-inset-2x.webp");
		}
		
		@media (max-width: 463px) {

			background-image: url("@{cdn3}/img/donors-donation-spotlight-inset.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/donors-donation-spotlight-inset.webp");
			}
		}
	}
}

.donors-donation-spotlight-inset-picture-background-ratio, .donors-donation-spotlight-inset-picture-background-with-ratio {
	.ratio(0.48812095032397407);
	background-position: center center;
	background-size: cover;
}

.donors-donation-spotlight-picture img {
	width: 1920px;
}

.ready-to-load-img .donors-donation-spotlight-picture-background, .ready-to-load-img .donors-donation-spotlight-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/donors-donation-spotlight.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/donors-donation-spotlight.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/donors-donation-spotlight-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/donors-donation-spotlight-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/donors-donation-spotlight-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/donors-donation-spotlight-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/donors-donation-spotlight.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/donors-donation-spotlight.webp");
			}
		}
	}
}

.donors-donation-spotlight-picture-background-ratio, .donors-donation-spotlight-picture-background-with-ratio {
	.ratio(0.23541666666666666);
	background-position: center center;
	background-size: cover;
}

.donors-header-picture img {
	width: 1920px;
}

.ready-to-load-img .donors-header-picture-background, .ready-to-load-img .donors-header-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/donors-header.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/donors-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/donors-header-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/donors-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/donors-header-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/donors-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/donors-header.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/donors-header.webp");
			}
		}
	}
}

.donors-header-picture-background-ratio, .donors-header-picture-background-with-ratio {
	.ratio(0.221875);
	background-position: center center;
	background-size: cover;
}

.ed-hruska-picture img {
	width: 283px;
}

.ready-to-load-img .ed-hruska-picture-background, .ready-to-load-img .ed-hruska-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/ed-hruska.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/ed-hruska.webp");
	}
	@media (max-width: 141.5px) {

		background-image: url("@{cdn2}/img/ed-hruska-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/ed-hruska-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/ed-hruska-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/ed-hruska-2x.webp");
		}
		
		@media (max-width: 141.5px) {

			background-image: url("@{cdn2}/img/ed-hruska.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/ed-hruska.webp");
			}
		}
	}
}

.ed-hruska-picture-background-ratio, .ed-hruska-picture-background-with-ratio {
	.ratio(1.1413427561837455);
	background-position: center center;
	background-size: cover;
}

.expando-arrow-picture img {
	width: 28px;
}

.ready-to-load-img .expando-arrow-picture-background, .ready-to-load-img .expando-arrow-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/expando-arrow.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/expando-arrow.webp");
	}
	@media (max-width: 14px) {

		background-image: url("@{cdn3}/img/expando-arrow-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/expando-arrow-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/expando-arrow-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/expando-arrow-2x.webp");
		}
		
		@media (max-width: 14px) {

			background-image: url("@{cdn3}/img/expando-arrow.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/expando-arrow.webp");
			}
		}
	}
}

.expando-arrow-picture-background-ratio, .expando-arrow-picture-background-with-ratio {
	.ratio(0.9285714285714286);
	background-position: center center;
	background-size: cover;
}

.facility-header-picture img {
	width: 1920px;
}

.ready-to-load-img .facility-header-picture-background, .ready-to-load-img .facility-header-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/facility-header.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/facility-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/facility-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/facility-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/facility-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/facility-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/facility-header.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/facility-header.webp");
			}
		}
	}
}

.facility-header-picture-background-ratio, .facility-header-picture-background-with-ratio {
	.ratio(0.221875);
	background-position: center center;
	background-size: cover;
}

.fargo-complex-picture img {
	width: 572px;
}

.ready-to-load-img .fargo-complex-picture-background, .ready-to-load-img .fargo-complex-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/fargo-complex.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/fargo-complex.webp");
	}
	@media (max-width: 286px) {

		background-image: url("@{cdn1}/img/fargo-complex-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/fargo-complex-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/fargo-complex-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/fargo-complex-2x.webp");
		}
		
		@media (max-width: 286px) {

			background-image: url("@{cdn1}/img/fargo-complex.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/fargo-complex.webp");
			}
		}
	}
}

.fargo-complex-picture-background-ratio, .fargo-complex-picture-background-with-ratio {
	.ratio(0.4143356643356643);
	background-position: center center;
	background-size: cover;
}

.get-involved-picture img {
	width: 1920px;
}

.ready-to-load-img .get-involved-picture-background, .ready-to-load-img .get-involved-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/get-involved.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/get-involved.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/get-involved-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/get-involved-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/get-involved-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/get-involved-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/get-involved.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/get-involved.webp");
			}
		}
	}
}

.get-involved-picture-background-ratio, .get-involved-picture-background-with-ratio {
	.ratio(0.165625);
	background-position: center center;
	background-size: cover;
}

.happening-tile-1-picture img {
	width: 407px;
}

.ready-to-load-img .happening-tile-1-picture-background, .ready-to-load-img .happening-tile-1-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/happening-tile-1.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/happening-tile-1.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn3}/img/happening-tile-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/happening-tile-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/happening-tile-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/happening-tile-1-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn3}/img/happening-tile-1.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/happening-tile-1.webp");
			}
		}
	}
}

.happening-tile-1-picture-background-ratio, .happening-tile-1-picture-background-with-ratio {
	.ratio(0.6928746928746928);
	background-position: center center;
	background-size: cover;
}

.happening-tile-2-picture img {
	width: 407px;
}

.ready-to-load-img .happening-tile-2-picture-background, .ready-to-load-img .happening-tile-2-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/happening-tile-2.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/happening-tile-2.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn0}/img/happening-tile-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/happening-tile-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/happening-tile-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/happening-tile-2-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn0}/img/happening-tile-2.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/happening-tile-2.webp");
			}
		}
	}
}

.happening-tile-2-picture-background-ratio, .happening-tile-2-picture-background-with-ratio {
	.ratio(0.7027027027027027);
	background-position: center center;
	background-size: cover;
}

.happening-tile-3-picture img {
	width: 407px;
}

.ready-to-load-img .happening-tile-3-picture-background, .ready-to-load-img .happening-tile-3-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/happening-tile-3.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/happening-tile-3.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn1}/img/happening-tile-3-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/happening-tile-3-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/happening-tile-3-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/happening-tile-3-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn1}/img/happening-tile-3.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/happening-tile-3.webp");
			}
		}
	}
}

.happening-tile-3-picture-background-ratio, .happening-tile-3-picture-background-with-ratio {
	.ratio(0.6928746928746928);
	background-position: center center;
	background-size: cover;
}

.happening-tile-4-picture img {
	width: 407px;
}

.ready-to-load-img .happening-tile-4-picture-background, .ready-to-load-img .happening-tile-4-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/happening-tile-4.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/happening-tile-4.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn2}/img/happening-tile-4-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/happening-tile-4-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/happening-tile-4-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/happening-tile-4-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn2}/img/happening-tile-4.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/happening-tile-4.webp");
			}
		}
	}
}

.happening-tile-4-picture-background-ratio, .happening-tile-4-picture-background-with-ratio {
	.ratio(0.7027027027027027);
	background-position: center center;
	background-size: cover;
}

.happening-tile-5-picture img {
	width: 407px;
}

.ready-to-load-img .happening-tile-5-picture-background, .ready-to-load-img .happening-tile-5-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/happening-tile-5.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/happening-tile-5.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn3}/img/happening-tile-5-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/happening-tile-5-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/happening-tile-5-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/happening-tile-5-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn3}/img/happening-tile-5.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/happening-tile-5.webp");
			}
		}
	}
}

.happening-tile-5-picture-background-ratio, .happening-tile-5-picture-background-with-ratio {
	.ratio(0.6928746928746928);
	background-position: center center;
	background-size: cover;
}

.happening-tile-6-picture img {
	width: 407px;
}

.ready-to-load-img .happening-tile-6-picture-background, .ready-to-load-img .happening-tile-6-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/happening-tile-6.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/happening-tile-6.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn0}/img/happening-tile-6-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/happening-tile-6-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/happening-tile-6-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/happening-tile-6-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn0}/img/happening-tile-6.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/happening-tile-6.webp");
			}
		}
	}
}

.happening-tile-6-picture-background-ratio, .happening-tile-6-picture-background-with-ratio {
	.ratio(0.7027027027027027);
	background-position: center center;
	background-size: cover;
}

.home-by-the-numbers-background-picture img {
	width: 1920px;
}

.ready-to-load-img .home-by-the-numbers-background-picture-background, .ready-to-load-img .home-by-the-numbers-background-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/home-by-the-numbers-background.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/home-by-the-numbers-background.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/home-by-the-numbers-background-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home-by-the-numbers-background-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/home-by-the-numbers-background-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home-by-the-numbers-background-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/home-by-the-numbers-background.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/home-by-the-numbers-background.webp");
			}
		}
	}
}

.home-by-the-numbers-background-picture-background-ratio, .home-by-the-numbers-background-picture-background-with-ratio {
	.ratio(0.2875);
	background-position: center center;
	background-size: cover;
}

.home-competes-with-the-best-picture img {
	width: 1920px;
}

.ready-to-load-img .home-competes-with-the-best-picture-background, .ready-to-load-img .home-competes-with-the-best-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/home-competes-with-the-best.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/home-competes-with-the-best.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/home-competes-with-the-best-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home-competes-with-the-best-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/home-competes-with-the-best-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home-competes-with-the-best-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/home-competes-with-the-best.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/home-competes-with-the-best.webp");
			}
		}
	}
}

.home-competes-with-the-best-picture-background-ratio, .home-competes-with-the-best-picture-background-with-ratio {
	.ratio(0.20885416666666667);
	background-position: center center;
	background-size: cover;
}

.home-developers-welcome-picture img {
	width: 1920px;
}

.ready-to-load-img .home-developers-welcome-picture-background, .ready-to-load-img .home-developers-welcome-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/home-developers-welcome.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/home-developers-welcome.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/home-developers-welcome-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home-developers-welcome-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/home-developers-welcome-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home-developers-welcome-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/home-developers-welcome.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/home-developers-welcome.webp");
			}
		}
	}
}

.home-developers-welcome-picture-background-ratio, .home-developers-welcome-picture-background-with-ratio {
	.ratio(0.14739583333333334);
	background-position: center center;
	background-size: cover;
}

.home-federal-picture img {
	width: 187px;
}

.ready-to-load-img .home-federal-picture-background, .ready-to-load-img .home-federal-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/home-federal.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/home-federal.webp");
	}
	@media (max-width: 93.5px) {

		background-image: url("@{cdn0}/img/home-federal-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/home-federal-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/home-federal-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/home-federal-2x.webp");
		}
		
		@media (max-width: 93.5px) {

			background-image: url("@{cdn0}/img/home-federal.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/home-federal.webp");
			}
		}
	}
}

.home-federal-picture-background-ratio, .home-federal-picture-background-with-ratio {
	.ratio(0.48128342245989303);
	background-position: center center;
	background-size: cover;
}

.home-get-involved-picture img {
	width: 1920px;
}

.ready-to-load-img .home-get-involved-picture-background, .ready-to-load-img .home-get-involved-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/home-get-involved.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/home-get-involved.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/home-get-involved-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home-get-involved-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/home-get-involved-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home-get-involved-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/home-get-involved.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/home-get-involved.webp");
			}
		}
	}
}

.home-get-involved-picture-background-ratio, .home-get-involved-picture-background-with-ratio {
	.ratio(0.165625);
	background-position: center center;
	background-size: cover;
}

.home-header-1-picture img {
	width: 1920px;
}

.ready-to-load-img .home-header-1-picture-background, .ready-to-load-img .home-header-1-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/home-header-1.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/home-header-1.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/home-header-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home-header-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/home-header-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home-header-1-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/home-header-1.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/home-header-1.webp");
			}
		}
	}
}

.home-header-1-picture-background-ratio, .home-header-1-picture-background-with-ratio {
	.ratio(0.38177083333333334);
	background-position: center center;
	background-size: cover;
}

.home-header-2-picture img {
	width: 1920px;
}

.ready-to-load-img .home-header-2-picture-background, .ready-to-load-img .home-header-2-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/home-header-2.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/home-header-2.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/home-header-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home-header-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/home-header-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home-header-2-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/home-header-2.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/home-header-2.webp");
			}
		}
	}
}

.home-header-2-picture-background-ratio, .home-header-2-picture-background-with-ratio {
	.ratio(0.38177083333333334);
	background-position: center center;
	background-size: cover;
}

.home-header-3-picture img {
	width: 1920px;
}

.ready-to-load-img .home-header-3-picture-background, .ready-to-load-img .home-header-3-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/home-header-3.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/home-header-3.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/home-header-3-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/home-header-3-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/home-header-3-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/home-header-3-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/home-header-3.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/home-header-3.webp");
			}
		}
	}
}

.home-header-3-picture-background-ratio, .home-header-3-picture-background-with-ratio {
	.ratio(0.38177083333333334);
	background-position: center center;
	background-size: cover;
}

.home-header-4-picture img {
	width: 1920px;
}

.ready-to-load-img .home-header-4-picture-background, .ready-to-load-img .home-header-4-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/home-header-4.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/home-header-4.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/home-header-4-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home-header-4-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/home-header-4-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home-header-4-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/home-header-4.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/home-header-4.webp");
			}
		}
	}
}

.home-header-4-picture-background-ratio, .home-header-4-picture-background-with-ratio {
	.ratio(0.38177083333333334);
	background-position: center center;
	background-size: cover;
}

.home-swimmer-picture img {
	width: 1920px;
}

.ready-to-load-img .home-swimmer-picture-background, .ready-to-load-img .home-swimmer-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/home-swimmer.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/home-swimmer.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/home-swimmer-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home-swimmer-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/home-swimmer-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home-swimmer-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/home-swimmer.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/home-swimmer.webp");
			}
		}
	}
}

.home-swimmer-picture-background-ratio, .home-swimmer-picture-background-with-ratio {
	.ratio(0.44869791666666664);
	background-position: center center;
	background-size: cover;
}

.home-welcome-background-picture img {
	width: 1920px;
}

.ready-to-load-img .home-welcome-background-picture-background, .ready-to-load-img .home-welcome-background-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/home-welcome-background.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/home-welcome-background.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/home-welcome-background-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home-welcome-background-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/home-welcome-background-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home-welcome-background-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/home-welcome-background.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/home-welcome-background.webp");
			}
		}
	}
}

.home-welcome-background-picture-background-ratio, .home-welcome-background-picture-background-with-ratio {
	.ratio(0.19635416666666666);
	background-position: center center;
	background-size: cover;
}

.interstate-development-picture img {
	width: 243px;
}

.ready-to-load-img .interstate-development-picture-background, .ready-to-load-img .interstate-development-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/interstate-development.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/interstate-development.webp");
	}
	@media (max-width: 121.5px) {

		background-image: url("@{cdn0}/img/interstate-development-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/interstate-development-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/interstate-development-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/interstate-development-2x.webp");
		}
		
		@media (max-width: 121.5px) {

			background-image: url("@{cdn0}/img/interstate-development.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/interstate-development.webp");
			}
		}
	}
}

.interstate-development-picture-background-ratio, .interstate-development-picture-background-with-ratio {
	.ratio(0.40329218106995884);
	background-position: center center;
	background-size: cover;
}

.landing-1-picture img {
	width: 1920px;
}

.ready-to-load-img .landing-1-picture-background, .ready-to-load-img .landing-1-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/landing-1.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/landing-1.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/landing-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/landing-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/landing-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/landing-1-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/landing-1.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/landing-1.webp");
			}
		}
	}
}

.landing-1-picture-background-ratio, .landing-1-picture-background-with-ratio {
	.ratio(0.3567708333333333);
	background-position: center center;
	background-size: cover;
}

.landing-2-picture img {
	width: 1920px;
}

.ready-to-load-img .landing-2-picture-background, .ready-to-load-img .landing-2-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/landing-2.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/landing-2.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/landing-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/landing-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/landing-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/landing-2-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/landing-2.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/landing-2.webp");
			}
		}
	}
}

.landing-2-picture-background-ratio, .landing-2-picture-background-with-ratio {
	.ratio(0.3567708333333333);
	background-position: center center;
	background-size: cover;
}

.landing-3-picture img {
	width: 1920px;
}

.ready-to-load-img .landing-3-picture-background, .ready-to-load-img .landing-3-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/landing-3.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/landing-3.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/landing-3-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/landing-3-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/landing-3-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/landing-3-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/landing-3.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/landing-3.webp");
			}
		}
	}
}

.landing-3-picture-background-ratio, .landing-3-picture-background-with-ratio {
	.ratio(0.3567708333333333);
	background-position: center center;
	background-size: cover;
}

.landing-5-picture img {
	width: 1920px;
}

.ready-to-load-img .landing-5-picture-background, .ready-to-load-img .landing-5-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/landing-5.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/landing-5.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/landing-5-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/landing-5-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/landing-5-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/landing-5-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/landing-5.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/landing-5.webp");
			}
		}
	}
}

.landing-5-picture-background-ratio, .landing-5-picture-background-with-ratio {
	.ratio(0.3567708333333333);
	background-position: center center;
	background-size: cover;
}

.landing-6-picture img {
	width: 1920px;
}

.ready-to-load-img .landing-6-picture-background, .ready-to-load-img .landing-6-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/landing-6.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/landing-6.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/landing-6-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/landing-6-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/landing-6-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/landing-6-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/landing-6.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/landing-6.webp");
			}
		}
	}
}

.landing-6-picture-background-ratio, .landing-6-picture-background-with-ratio {
	.ratio(0.3567708333333333);
	background-position: center center;
	background-size: cover;
}

.location-state-picture img {
	width: 307px;
}

.ready-to-load-img .location-state-picture-background, .ready-to-load-img .location-state-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/location-state.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/location-state.webp");
	}
	@media (max-width: 153.5px) {

		background-image: url("@{cdn2}/img/location-state-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/location-state-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/location-state-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/location-state-2x.webp");
		}
		
		@media (max-width: 153.5px) {

			background-image: url("@{cdn2}/img/location-state.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/location-state.webp");
			}
		}
	}
}

.location-state-picture-background-ratio, .location-state-picture-background-with-ratio {
	.ratio(1.1172638436482085);
	background-position: center center;
	background-size: cover;
}

.logo-picture img {
	width: 266px;
}

.ready-to-load-img .logo-picture-background, .ready-to-load-img .logo-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/logo.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/logo.webp");
	}
	@media (max-width: 133px) {

		background-image: url("@{cdn3}/img/logo-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/logo-2x.webp");
		}
		
		@media (max-width: 133px) {

			background-image: url("@{cdn3}/img/logo.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/logo.webp");
			}
		}
	}
}

.logo-picture-background-ratio, .logo-picture-background-with-ratio {
	.ratio(0.424812030075188);
	background-position: center center;
	background-size: cover;
}

.looking-back-header-picture img {
	width: 1920px;
}

.ready-to-load-img .looking-back-header-picture-background, .ready-to-load-img .looking-back-header-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/looking-back-header.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/looking-back-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/looking-back-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/looking-back-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/looking-back-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/looking-back-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/looking-back-header.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/looking-back-header.webp");
			}
		}
	}
}

.looking-back-header-picture-background-ratio, .looking-back-header-picture-background-with-ratio {
	.ratio(0.22864583333333333);
	background-position: center center;
	background-size: cover;
}

.looking-back-tile-1-picture img {
	width: 407px;
}

.ready-to-load-img .looking-back-tile-1-picture-background, .ready-to-load-img .looking-back-tile-1-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/looking-back-tile-1.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/looking-back-tile-1.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn1}/img/looking-back-tile-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/looking-back-tile-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/looking-back-tile-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/looking-back-tile-1-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn1}/img/looking-back-tile-1.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/looking-back-tile-1.webp");
			}
		}
	}
}

.looking-back-tile-1-picture-background-ratio, .looking-back-tile-1-picture-background-with-ratio {
	.ratio(0.6928746928746928);
	background-position: center center;
	background-size: cover;
}

.looking-back-tile-2-picture img {
	width: 407px;
}

.ready-to-load-img .looking-back-tile-2-picture-background, .ready-to-load-img .looking-back-tile-2-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/looking-back-tile-2.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/looking-back-tile-2.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn2}/img/looking-back-tile-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/looking-back-tile-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/looking-back-tile-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/looking-back-tile-2-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn2}/img/looking-back-tile-2.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/looking-back-tile-2.webp");
			}
		}
	}
}

.looking-back-tile-2-picture-background-ratio, .looking-back-tile-2-picture-background-with-ratio {
	.ratio(0.6928746928746928);
	background-position: center center;
	background-size: cover;
}

.looking-back-tile-3-picture img {
	width: 407px;
}

.ready-to-load-img .looking-back-tile-3-picture-background, .ready-to-load-img .looking-back-tile-3-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/looking-back-tile-3.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/looking-back-tile-3.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn3}/img/looking-back-tile-3-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/looking-back-tile-3-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/looking-back-tile-3-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/looking-back-tile-3-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn3}/img/looking-back-tile-3.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/looking-back-tile-3.webp");
			}
		}
	}
}

.looking-back-tile-3-picture-background-ratio, .looking-back-tile-3-picture-background-with-ratio {
	.ratio(0.6928746928746928);
	background-position: center center;
	background-size: cover;
}

.meeting-minutes-picture img {
	width: 1920px;
}

.ready-to-load-img .meeting-minutes-picture-background, .ready-to-load-img .meeting-minutes-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/meeting-minutes.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/meeting-minutes.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/meeting-minutes-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/meeting-minutes-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/meeting-minutes-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/meeting-minutes-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/meeting-minutes.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/meeting-minutes.webp");
			}
		}
	}
}

.meeting-minutes-picture-background-ratio, .meeting-minutes-picture-background-with-ratio {
	.ratio(0.26848958333333334);
	background-position: center center;
	background-size: cover;
}

.minn-roch-sports-picture img {
	width: 343px;
}

.ready-to-load-img .minn-roch-sports-picture-background, .ready-to-load-img .minn-roch-sports-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/minn-roch-sports.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/minn-roch-sports.webp");
	}
	@media (max-width: 171.5px) {

		background-image: url("@{cdn1}/img/minn-roch-sports-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/minn-roch-sports-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/minn-roch-sports-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/minn-roch-sports-2x.webp");
		}
		
		@media (max-width: 171.5px) {

			background-image: url("@{cdn1}/img/minn-roch-sports.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/minn-roch-sports.webp");
			}
		}
	}
}

.minn-roch-sports-picture-background-ratio, .minn-roch-sports-picture-background-with-ratio {
	.ratio(0.3760932944606414);
	background-position: center center;
	background-size: cover;
}

.nobelsville-picture img {
	width: 522px;
}

.ready-to-load-img .nobelsville-picture-background, .ready-to-load-img .nobelsville-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/nobelsville.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/nobelsville.webp");
	}
	@media (max-width: 261px) {

		background-image: url("@{cdn2}/img/nobelsville-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/nobelsville-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/nobelsville-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/nobelsville-2x.webp");
		}
		
		@media (max-width: 261px) {

			background-image: url("@{cdn2}/img/nobelsville.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/nobelsville.webp");
			}
		}
	}
}

.nobelsville-picture-background-ratio, .nobelsville-picture-background-with-ratio {
	.ratio(0.7088122605363985);
	background-position: center center;
	background-size: cover;
}

.olmsted-county-picture img {
	width: 187px;
}

.ready-to-load-img .olmsted-county-picture-background, .ready-to-load-img .olmsted-county-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/olmsted-county.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/olmsted-county.webp");
	}
	@media (max-width: 93.5px) {

		background-image: url("@{cdn3}/img/olmsted-county-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/olmsted-county-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/olmsted-county-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/olmsted-county-2x.webp");
		}
		
		@media (max-width: 93.5px) {

			background-image: url("@{cdn3}/img/olmsted-county.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/olmsted-county.webp");
			}
		}
	}
}

.olmsted-county-picture-background-ratio, .olmsted-county-picture-background-with-ratio {
	.ratio(0.7005347593582888);
	background-position: center center;
	background-size: cover;
}

.outside-the-spots-box-inset-picture img {
	width: 785px;
}

.ready-to-load-img .outside-the-spots-box-inset-picture-background, .ready-to-load-img .outside-the-spots-box-inset-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/outside-the-spots-box-inset.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/outside-the-spots-box-inset.webp");
	}
	@media (max-width: 392.5px) {

		background-image: url("@{cdn0}/img/outside-the-spots-box-inset-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/outside-the-spots-box-inset-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/outside-the-spots-box-inset-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/outside-the-spots-box-inset-2x.webp");
		}
		
		@media (max-width: 392.5px) {

			background-image: url("@{cdn0}/img/outside-the-spots-box-inset.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/outside-the-spots-box-inset.webp");
			}
		}
	}
}

.outside-the-spots-box-inset-picture-background-ratio, .outside-the-spots-box-inset-picture-background-with-ratio {
	.ratio(0.5770700636942675);
	background-position: center center;
	background-size: cover;
}

.pasco-picture img {
	width: 522px;
}

.ready-to-load-img .pasco-picture-background, .ready-to-load-img .pasco-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/pasco.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/pasco.webp");
	}
	@media (max-width: 261px) {

		background-image: url("@{cdn1}/img/pasco-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/pasco-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/pasco-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/pasco-2x.webp");
		}
		
		@media (max-width: 261px) {

			background-image: url("@{cdn1}/img/pasco.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/pasco.webp");
			}
		}
	}
}

.pasco-picture-background-ratio, .pasco-picture-background-with-ratio {
	.ratio(0.7088122605363985);
	background-position: center center;
	background-size: cover;
}

.pdf-icon-picture img {
	width: 16.5px;
}

.ready-to-load-img .pdf-icon-picture-background, .ready-to-load-img .pdf-icon-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/pdf-icon.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/pdf-icon.webp");
	}
	@media (max-width: 8.25px) {

		background-image: url("@{cdn2}/img/pdf-icon-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/pdf-icon-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/pdf-icon-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/pdf-icon-2x.webp");
		}
		
		@media (max-width: 8.25px) {

			background-image: url("@{cdn2}/img/pdf-icon.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/pdf-icon.webp");
			}
		}
	}
}

.pdf-icon-picture-background-ratio, .pdf-icon-picture-background-with-ratio {
	.ratio(1.303030303030303);
	background-position: center center;
	background-size: cover;
}

.pool-audience-square-picture img {
	width: 719.5px;
}

.ready-to-load-img .pool-audience-square-picture-background, .ready-to-load-img .pool-audience-square-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/pool-audience-square.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/pool-audience-square.webp");
	}
	@media (max-width: 359.75px) {

		background-image: url("@{cdn3}/img/pool-audience-square-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/pool-audience-square-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/pool-audience-square-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/pool-audience-square-2x.webp");
		}
		
		@media (max-width: 359.75px) {

			background-image: url("@{cdn3}/img/pool-audience-square.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/pool-audience-square.webp");
			}
		}
	}
}

.pool-audience-square-picture-background-ratio, .pool-audience-square-picture-background-with-ratio {
	.ratio(0.5573314801945796);
	background-position: center center;
	background-size: cover;
}

.pool-audience-picture img {
	width: 781px;
}

.ready-to-load-img .pool-audience-picture-background, .ready-to-load-img .pool-audience-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/pool-audience.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/pool-audience.webp");
	}
	@media (max-width: 390.5px) {

		background-image: url("@{cdn0}/img/pool-audience-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/pool-audience-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/pool-audience-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/pool-audience-2x.webp");
		}
		
		@media (max-width: 390.5px) {

			background-image: url("@{cdn0}/img/pool-audience.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/pool-audience.webp");
			}
		}
	}
}

.pool-audience-picture-background-ratio, .pool-audience-picture-background-with-ratio {
	.ratio(0.5134443021766966);
	background-position: center center;
	background-size: cover;
}

.premier-bank-picture img {
	width: 267px;
}

.ready-to-load-img .premier-bank-picture-background, .ready-to-load-img .premier-bank-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/premier-bank.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/premier-bank.webp");
	}
	@media (max-width: 133.5px) {

		background-image: url("@{cdn1}/img/premier-bank-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/premier-bank-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/premier-bank-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/premier-bank-2x.webp");
		}
		
		@media (max-width: 133.5px) {

			background-image: url("@{cdn1}/img/premier-bank.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/premier-bank.webp");
			}
		}
	}
}

.premier-bank-picture-background-ratio, .premier-bank-picture-background-with-ratio {
	.ratio(0.18352059925093633);
	background-position: center center;
	background-size: cover;
}

.roch-skyline-picture img {
	width: 1920px;
}

.ready-to-load-img .roch-skyline-picture-background, .ready-to-load-img .roch-skyline-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/roch-skyline.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/roch-skyline.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/roch-skyline-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/roch-skyline-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/roch-skyline-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/roch-skyline-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/roch-skyline.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/roch-skyline.webp");
			}
		}
	}
}

.roch-skyline-picture-background-ratio, .roch-skyline-picture-background-with-ratio {
	.ratio(0.5026041666666666);
	background-position: center center;
	background-size: cover;
}

.rochester-motor-cars-picture img {
	width: 268px;
}

.ready-to-load-img .rochester-motor-cars-picture-background, .ready-to-load-img .rochester-motor-cars-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/rochester-motor-cars.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/rochester-motor-cars.webp");
	}
	@media (max-width: 134px) {

		background-image: url("@{cdn3}/img/rochester-motor-cars-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/rochester-motor-cars-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/rochester-motor-cars-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/rochester-motor-cars-2x.webp");
		}
		
		@media (max-width: 134px) {

			background-image: url("@{cdn3}/img/rochester-motor-cars.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/rochester-motor-cars.webp");
			}
		}
	}
}

.rochester-motor-cars-picture-background-ratio, .rochester-motor-cars-picture-background-with-ratio {
	.ratio(0.23134328358208955);
	background-position: center center;
	background-size: cover;
}

.rochester-sports-foundation-article-generic-picture img {
	width: 522px;
}

.ready-to-load-img .rochester-sports-foundation-article-generic-picture-background, .ready-to-load-img .rochester-sports-foundation-article-generic-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/rochester-sports-foundation-article-generic.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/rochester-sports-foundation-article-generic.webp");
	}
	@media (max-width: 261px) {

		background-image: url("@{cdn0}/img/rochester-sports-foundation-article-generic-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/rochester-sports-foundation-article-generic-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/rochester-sports-foundation-article-generic-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/rochester-sports-foundation-article-generic-2x.webp");
		}
		
		@media (max-width: 261px) {

			background-image: url("@{cdn0}/img/rochester-sports-foundation-article-generic.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/rochester-sports-foundation-article-generic.webp");
			}
		}
	}
}

.rochester-sports-foundation-article-generic-picture-background-ratio, .rochester-sports-foundation-article-generic-picture-background-with-ratio {
	.ratio(0.7088122605363985);
	background-position: center center;
	background-size: cover;
}

.rsf-logo-picture img {
	width: 307px;
}

.ready-to-load-img .rsf-logo-picture-background, .ready-to-load-img .rsf-logo-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/rsf-logo.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/rsf-logo.webp");
	}
	@media (max-width: 153.5px) {

		background-image: url("@{cdn1}/img/rsf-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/rsf-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/rsf-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/rsf-logo-2x.webp");
		}
		
		@media (max-width: 153.5px) {

			background-image: url("@{cdn1}/img/rsf-logo.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/rsf-logo.webp");
			}
		}
	}
}

.rsf-logo-picture-background-ratio, .rsf-logo-picture-background-with-ratio {
	.ratio(0.42996742671009774);
	background-position: center center;
	background-size: cover;
}

.sports-tourism-articles-picture img {
	width: 1920px;
}

.ready-to-load-img .sports-tourism-articles-picture-background, .ready-to-load-img .sports-tourism-articles-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/sports-tourism-articles.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/sports-tourism-articles.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/sports-tourism-articles-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/sports-tourism-articles-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/sports-tourism-articles-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/sports-tourism-articles-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/sports-tourism-articles.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/sports-tourism-articles.webp");
			}
		}
	}
}

.sports-tourism-articles-picture-background-ratio, .sports-tourism-articles-picture-background-with-ratio {
	.ratio(0.5041666666666667);
	background-position: center center;
	background-size: cover;
}

.sports-tourism-header-picture img {
	width: 1920px;
}

.ready-to-load-img .sports-tourism-header-picture-background, .ready-to-load-img .sports-tourism-header-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/sports-tourism-header.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/sports-tourism-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/sports-tourism-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/sports-tourism-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/sports-tourism-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/sports-tourism-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/sports-tourism-header.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/sports-tourism-header.webp");
			}
		}
	}
}

.sports-tourism-header-picture-background-ratio, .sports-tourism-header-picture-background-with-ratio {
	.ratio(0.221875);
	background-position: center center;
	background-size: cover;
}

.sports-tourism-tile-1-picture img {
	width: 261px;
}

.ready-to-load-img .sports-tourism-tile-1-picture-background, .ready-to-load-img .sports-tourism-tile-1-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/sports-tourism-tile-1.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/sports-tourism-tile-1.webp");
	}
	@media (max-width: 130.5px) {

		background-image: url("@{cdn0}/img/sports-tourism-tile-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/sports-tourism-tile-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/sports-tourism-tile-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/sports-tourism-tile-1-2x.webp");
		}
		
		@media (max-width: 130.5px) {

			background-image: url("@{cdn0}/img/sports-tourism-tile-1.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/sports-tourism-tile-1.webp");
			}
		}
	}
}

.sports-tourism-tile-1-picture-background-ratio, .sports-tourism-tile-1-picture-background-with-ratio {
	.ratio(0.7088122605363985);
	background-position: center center;
	background-size: cover;
}

.sports-tourism-tile-2-picture img {
	width: 261px;
}

.ready-to-load-img .sports-tourism-tile-2-picture-background, .ready-to-load-img .sports-tourism-tile-2-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/sports-tourism-tile-2.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/sports-tourism-tile-2.webp");
	}
	@media (max-width: 130.5px) {

		background-image: url("@{cdn1}/img/sports-tourism-tile-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/sports-tourism-tile-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/sports-tourism-tile-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/sports-tourism-tile-2-2x.webp");
		}
		
		@media (max-width: 130.5px) {

			background-image: url("@{cdn1}/img/sports-tourism-tile-2.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/sports-tourism-tile-2.webp");
			}
		}
	}
}

.sports-tourism-tile-2-picture-background-ratio, .sports-tourism-tile-2-picture-background-with-ratio {
	.ratio(0.7088122605363985);
	background-position: center center;
	background-size: cover;
}

.sports-tourism-tile-3-picture img {
	width: 261px;
}

.ready-to-load-img .sports-tourism-tile-3-picture-background, .ready-to-load-img .sports-tourism-tile-3-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/sports-tourism-tile-3.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/sports-tourism-tile-3.webp");
	}
	@media (max-width: 130.5px) {

		background-image: url("@{cdn2}/img/sports-tourism-tile-3-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/sports-tourism-tile-3-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/sports-tourism-tile-3-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/sports-tourism-tile-3-2x.webp");
		}
		
		@media (max-width: 130.5px) {

			background-image: url("@{cdn2}/img/sports-tourism-tile-3.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/sports-tourism-tile-3.webp");
			}
		}
	}
}

.sports-tourism-tile-3-picture-background-ratio, .sports-tourism-tile-3-picture-background-with-ratio {
	.ratio(0.7088122605363985);
	background-position: center center;
	background-size: cover;
}

.sports-tourism-tile-4-picture img {
	width: 261px;
}

.ready-to-load-img .sports-tourism-tile-4-picture-background, .ready-to-load-img .sports-tourism-tile-4-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/sports-tourism-tile-4.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/sports-tourism-tile-4.webp");
	}
	@media (max-width: 130.5px) {

		background-image: url("@{cdn3}/img/sports-tourism-tile-4-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/sports-tourism-tile-4-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/sports-tourism-tile-4-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/sports-tourism-tile-4-2x.webp");
		}
		
		@media (max-width: 130.5px) {

			background-image: url("@{cdn3}/img/sports-tourism-tile-4.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/sports-tourism-tile-4.webp");
			}
		}
	}
}

.sports-tourism-tile-4-picture-background-ratio, .sports-tourism-tile-4-picture-background-with-ratio {
	.ratio(0.7088122605363985);
	background-position: center center;
	background-size: cover;
}

.sports-tourism-tile-5-picture img {
	width: 261px;
}

.ready-to-load-img .sports-tourism-tile-5-picture-background, .ready-to-load-img .sports-tourism-tile-5-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/sports-tourism-tile-5.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/sports-tourism-tile-5.webp");
	}
	@media (max-width: 130.5px) {

		background-image: url("@{cdn0}/img/sports-tourism-tile-5-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/sports-tourism-tile-5-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/sports-tourism-tile-5-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/sports-tourism-tile-5-2x.webp");
		}
		
		@media (max-width: 130.5px) {

			background-image: url("@{cdn0}/img/sports-tourism-tile-5.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/sports-tourism-tile-5.webp");
			}
		}
	}
}

.sports-tourism-tile-5-picture-background-ratio, .sports-tourism-tile-5-picture-background-with-ratio {
	.ratio(0.7088122605363985);
	background-position: center center;
	background-size: cover;
}

.sports-tourism-tile-6-picture img {
	width: 261px;
}

.ready-to-load-img .sports-tourism-tile-6-picture-background, .ready-to-load-img .sports-tourism-tile-6-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/sports-tourism-tile-6.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/sports-tourism-tile-6.webp");
	}
	@media (max-width: 130.5px) {

		background-image: url("@{cdn1}/img/sports-tourism-tile-6-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/sports-tourism-tile-6-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/sports-tourism-tile-6-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/sports-tourism-tile-6-2x.webp");
		}
		
		@media (max-width: 130.5px) {

			background-image: url("@{cdn1}/img/sports-tourism-tile-6.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/sports-tourism-tile-6.webp");
			}
		}
	}
}

.sports-tourism-tile-6-picture-background-ratio, .sports-tourism-tile-6-picture-background-with-ratio {
	.ratio(0.7088122605363985);
	background-position: center center;
	background-size: cover;
}

.sports-tourism-picture img {
	width: 1920px;
}

.ready-to-load-img .sports-tourism-picture-background, .ready-to-load-img .sports-tourism-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/sports-tourism.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/sports-tourism.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/sports-tourism-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/sports-tourism-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/sports-tourism-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/sports-tourism-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/sports-tourism.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/sports-tourism.webp");
			}
		}
	}
}

.sports-tourism-picture-background-ratio, .sports-tourism-picture-background-with-ratio {
	.ratio(0.21927083333333333);
	background-position: center center;
	background-size: cover;
}

.think-bank-picture img {
	width: 144px;
}

.ready-to-load-img .think-bank-picture-background, .ready-to-load-img .think-bank-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/think-bank.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/think-bank.webp");
	}
	@media (max-width: 72px) {

		background-image: url("@{cdn3}/img/think-bank-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/think-bank-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/think-bank-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/think-bank-2x.webp");
		}
		
		@media (max-width: 72px) {

			background-image: url("@{cdn3}/img/think-bank.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/think-bank.webp");
			}
		}
	}
}

.think-bank-picture-background-ratio, .think-bank-picture-background-with-ratio {
	.ratio(0.5208333333333334);
	background-position: center center;
	background-size: cover;
}

.us-map-2-picture img {
	width: 1920px;
}

.ready-to-load-img .us-map-2-picture-background, .ready-to-load-img .us-map-2-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/us-map-2.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/us-map-2.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/us-map-2-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/us-map-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/us-map-2-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/us-map-2-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/us-map-2.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/us-map-2.webp");
			}
		}
	}
}

.us-map-2-picture-background-ratio, .us-map-2-picture-background-with-ratio {
	.ratio(0.2640625);
	background-position: center center;
	background-size: cover;
}

.us-map-3-picture img {
	width: 799px;
}

.ready-to-load-img .us-map-3-picture-background, .ready-to-load-img .us-map-3-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/us-map-3.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/us-map-3.webp");
	}
	@media (max-width: 399.5px) {

		background-image: url("@{cdn1}/img/us-map-3-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/us-map-3-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/us-map-3-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/us-map-3-2x.webp");
		}
		
		@media (max-width: 399.5px) {

			background-image: url("@{cdn1}/img/us-map-3.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/us-map-3.webp");
			}
		}
	}
}

.us-map-3-picture-background-ratio, .us-map-3-picture-background-with-ratio {
	.ratio(0.6182728410513142);
	background-position: center center;
	background-size: cover;
}

.volley-ball-hands-reaching-picture img {
	width: 1920px;
}

.ready-to-load-img .volley-ball-hands-reaching-picture-background, .ready-to-load-img .volley-ball-hands-reaching-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/volley-ball-hands-reaching.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/volley-ball-hands-reaching.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/volley-ball-hands-reaching-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/volley-ball-hands-reaching-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/volley-ball-hands-reaching-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/volley-ball-hands-reaching-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/volley-ball-hands-reaching.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/volley-ball-hands-reaching.webp");
			}
		}
	}
}

.volley-ball-hands-reaching-picture-background-ratio, .volley-ball-hands-reaching-picture-background-with-ratio {
	.ratio(0.2875);
	background-position: center center;
	background-size: cover;
}

.whats-happening-header-picture img {
	width: 1920px;
}

.ready-to-load-img .whats-happening-header-picture-background, .ready-to-load-img .whats-happening-header-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/whats-happening-header.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/whats-happening-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/whats-happening-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/whats-happening-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/whats-happening-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/whats-happening-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/whats-happening-header.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/whats-happening-header.webp");
			}
		}
	}
}

.whats-happening-header-picture-background-ratio, .whats-happening-header-picture-background-with-ratio {
	.ratio(0.221875);
	background-position: center center;
	background-size: cover;
}
