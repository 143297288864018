
.setup-width-definitions() {
	@container-width:			1170px;
	@tablet-width: 				900px;
	@tablet-small-width:        716px;
	@big-phone-width:			650px;
	@phone-width:               550px;
	@iphone-4-landscape-width:	480px;
	@iphone-4-width: 			320px;
	@page-side-padding: 		20px;

}

.setup-width-definitions();


/*
 * The base font size which makes the chosen font match photoshop.
 * For Open Sans this is 7.1pt, for Source Sans Pro this is 7.6pt 
 */
@base-font-size:			7.4pt;
@barlowc-base-font-size:	7.5pt;

@grey-box-background: 		#f0f1f3;
@grey-box-darker: 			#d6d9e2;
@grey-body-text:			#444444;


@blue-light: 				#7d9be2;
@blue-menu-top: 			#15264e;
@blue-menu-bottom: 			#0b1224;
@blue-dark: 				#081124;

@red: 						#bd1f28;

.barlow(@size) {
	font-family: 'Barlow', sans-serif;
	font-size: @base-font-size * @size / 10;
}
.barlowc(@size) {
	font-family: 'Barlow Condensed', sans-serif;
	font-size: @barlowc-base-font-size * @size / 10;
}


.menu-link() {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}	

.link-inherit() {
	a {
		.menu-link;
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		font-family: inherit;
	}
}


h1, h2, h3, h4, h5 {
	.link-inherit;
}

body {
    color: @grey-body-text;
}

