

.std-header {

	
	.logo {
		color: white;
		text-decoration: none;
		font-size: 0;
		img {
			max-width: 60%;
		}
	}
	
	.menu {
		list-style: none;
		li {
			margin-left: 10px;
			padding-bottom: 10px;
			a {
				.fs(20);
				color: white;
				text-transform: uppercase;
				text-decoration: none;
				font-weight: 800;
				padding-left: 10px;
				padding-right: 10px;
				padding-bottom: 6px;	
				padding-top: 2px;
			}
		}
	}

	.desktop({
	
		nav {
			justify-self: right;
			align-self: center;
			text-align: right;

		}
		.menu {
			display: inline-block;
			margin: 0;
			padding: 0;
			li {
				display: inline-block;
				a {
				}
				
				&.current {
					border-bottom: solid 2px white;
				}
				
			}
		}
		.tribar {
			display: none;
		}
		.logo {
			margin-top: -25px;
			display: inline-block;
		}
	});
	.desktop-and-smaller({
		nav {
			position: fixed;
			width: 100%;
			width: 100vw;
			height: 100%;
			height: 100vh;
			z-index: 5;
			top: 0;
			left: -20000px;
			max-width: 350px;
			opacity: 0;
			transition: opacity .3s;
			overflow: auto;
			padding: 50px 15px 0 15px;
			box-sizing: border-box;
			background-color: black; 
			.menu {
				padding: 0;
			}
			
			& > li {
				display: block;
				text-align: center;
				
				& > a {
					text-align: center;
					font-weight: 600;
				}
				
				& > ul {
					position: relative;
					left: auto;
					opacity: 1;
					text-align: center;
					
					a {
						text-align: center;
					}
				}
			}
			
			&.open {
				left: 0;
				opacity: 1;
			}
		}

		.tribar {
			width: 30px;
			margin: 10px 10px 0 0;
			cursor: pointer;
			position: absolute;
			top: 20px;
			right: 10px;
			
			.one, .two, .three {
				border-bottom: solid 3px;
				border-color: white;
			}
			.two {
				margin: 7px 0;
			}
			
		}
	});

}

header {
	.std-header;
}

.std-txt {
	h2 {
		.barlowc(44);
		font-style: italic;
		text-transform: uppercase;
		.red-dash-headline;
		
		.solid-button {
			vertical-align: middle;
			margin-top: -5px;
			margin-left: 15px;
		}
		
		&.blue-and-sized {
			.barlowc(66);
			color: @blue-light !important;
			
			smaller {
				.barlowc(44);
				color: white !important;
			}
			
		}
		
		&.no-line {
			&:after {
				display: none;
			}
		}
	}
	
	h3 {
		.barlowc(35);
		color: @blue-light;
		text-transform: uppercase;
		font-style: italic;
	}
	
	p {
		.barlow(20);
		font-weight: 300;
	}
	img {
		max-width: 100%;
	}
	
}

.svg-icons {
	position: absolute;
	left: -20000px;
}

.page-landing {
	.banner {
		.full-back;
		.container {
			.ratio(0.588888889);
			font-size: 0;
			.msg {
				display: inline-block;
				max-width: 100%;
				font-size: 1rem;
				vertical-align: middle;
				padding-top: 10%;
			}
			
		}
		
		h1, p {
			color: white;
		}
		
		h1 {
			.barlowc(106);
			font-weight: 600;
			font-style: italic;
			text-transform: uppercase;
			margin-top: .25em;
			margin-bottom: .25em;
		}
		
		p {
			.barlow(23);
			font-weight: 300;
		}
	}
	.contact-area {
		.msg {
			display: inline-block;
			color: @blue-light;
			border-top: solid 5px @red;
			h2 {
				.barlowc(31);
				color: @blue-light;
				font-style: italic;
				text-transform: uppercase;
				margin-top: 1.5em;
			}
			
			h3 {
				.barlowc(21);
				font-style: italic;
				color: @blue-light;
				margin-bottom: 0;
			}
			
			a {
				color: @blue-light;
				text-decoration: none;
			}
			
			h3 + p {
				// margin-top: 0;
			}
			
			.env {
				height: 25px;
				width: 22px;
				vertical-align: middle;
				fill: @blue-light;
				display: inline-block;
				padding-right: 10px;
			}
			.facebook, .linkedin {
				width: 30px;
				padding-right: 15px;
				vertical-align: middle;
				fill: @blue-light;
				display: inline-block;
				padding-right: 10px;
			}
		}
		
		

	}
}

html.all-blue {
	background-color: @blue-dark;
}

.shiner-show {
	position: relative;
	
	.slide {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity 2s;
	}
	
	.slide:first-child {
		opacity: 1;
	}

	.slide.invisible {
		opacity: 0;
	}

	.slide.visible {
		opacity: 1;
	}
	
	.container {
		position: relative;
		z-index: 5;
	}
}

.page-top-message {
	background-color: @blue-menu-top;
	color: @blue-light;
	text-align: center;
	padding-left: @page-side-padding;
	padding-right: @page-side-padding;
	padding-top: 10px;
	padding-bottom: 10px;
	color: white;
	

}


.top-bar {
	padding: 10px 20px; 
	background-color: @blue-menu-top;
	color: @blue-light;
	text-align: right;

	a {
		.barlowc(17);
		display: inline-block;
		color: @blue-light;
		text-decoration: none;
		margin-left: 20px;
		font-weight: 300;
		.tablet-and-smaller({
			padding-top: 10px;
			padding-bottom: 10px;
		});
	}
	.facebook, .linkedin {
		width: 30px;
		vertical-align: middle;
		fill: @blue-light;
		display: inline-block;
		max-height: 36px;
	}
	
	.solid-button {
		color: white;
		font-weight: 600;
		background-color: @blue-light;
	}
}

header.inner-header {
	.bottom {
		background-position: center center;
		background-size: cover;
		position: relative;
		padding-top: 1px;
		
		.link-bar {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			
			background-color: fade(@blue-menu-bottom, 80%);

		}
		
		.container.headline {
			margin-top: 80px;
			&:before {
				content: '\00a0';
				font-size: 0;
				display: inline-block;
				padding-top: 175px;
				padding-bottom: 167px;
				vertical-align: middle;
			}
			
			h1 {
				display: inline-block;
				color: white;
				.barlowc(80);
				font-style: italic;
				text-transform: uppercase;
			}

		}
		
		.tablet({
			.link-bar {
				.container {
					display: grid;
					grid-template-columns: auto 1fr;
					padding-bottom: 20px;
					
					nav {
						align-self: end;
					}
				}

			}
		});
		
		nav {
			a {
				.barlowc(19);
			}
		}
	}
}

.group-names {
	margin-bottom: 60px;
}

.grey-back.container-wrapper {
	background-color: @grey-box-background;
	padding-top: 10px;
	padding-bottom: 60px;
}
.blue-back.container-wrapper {
	background-color: @blue-dark;
	padding-top: 10px;
	padding-bottom: 10px;
	h2 {
		.red-dash-headline;
		color: white;
	}
}

section.meeting-minutes {
	padding-top: 1px;
	padding-bottom: 30px;
	h2 {
		color: white;
		line-height: 1em;
		strong {
			color: @blue-light;
		}
	}
	
	.documents {
		margin-top: 30px;
		
		.tablet({
			columns: 2;
		});
	}
}

section.additional-resources {
	padding-top: 1px;
	padding-bottom: 30px;
	
	.documents {
		margin-top: 30px;
		.meeting-minutes-link {
			color: @grey-body-text;
			border-bottom-color: @grey-body-text;
			max-width: @container-width / 2;
			svg {
				fill: @blue-light;
			}
			
			&:last-child {
				border-bottom: 0;
			}
		}
		
	}
}

footer {
	.links {
		background-color: @blue-dark;
		color: white;
		padding-top: 30px;
		padding-bottom: 30px;
		
		.brand {
			padding-right: 40px;
			p {
				.barlow(15);
				margin-top: 30px;
				text-align: center;
				padding-left: 10px;
				padding-right: 10px;
			}
		}
		
		.menu-holder {
			ul {
				list-style: none;
				padding-left: 0;
				a {
					.barlowc(20);
					text-transform: uppercase;
					color: white;
					text-decoration: none;
					font-weight: 600;
				}
			}

			.facebook, .linkedin, .env {
				width: 30px;
				max-height: 30px;
				padding-right: 15px;
				vertical-align: middle;
				fill: @blue-light;
				display: inline-block;
				padding-right: 10px;
			}
		}
		
		.contact {
			h3 {
				.barlowc(20);
				text-transform: uppercase;
				color: white;
				text-decoration: none;
				font-weight: 600;

			}
			form {
				color: white;
				input, textarea {
					display: block;
					background: transparent;
					color: white;
					box-sizing: border-box;
					border: solid 1px @blue-light;
					padding: 8px 4px;
					width: 100%;
					margin-bottom: 5px;
					&::placeholder {
						.barlowc(20);
						font-weight: 300;
						color: white;
					}
				}
				button {
					margin-top: 12px;
				}
			}
		}
		
		.container {
			display: grid;
			column-gap: 30px;
			row-gap: 30px;
		}
		.desktop({
			.container {
				grid-template-columns: 3fr 2fr 5fr;
				

			}
		});
	}
	
	.copyright {
		background-color: @blue-menu-top;
		color: @blue-light;
		text-align: center;
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.sponsors {
	text-align: center;
	.sponsor {
		display: inline-block;
		margin: 50px;
		vertical-align: middle;
		img {
			max-width: 200px;
		}
	}
}

.donor-level-list {
	ul {
		list-style: none;
		column-gap: 30px;

		li {
			margin-bottom: 10px;
			font-weight: 600;
		}
		.tablet({
			columns: 2;
		});
		.desktop({
			columns: 3;
		});
	}
}

.donor-groups {
	margin-bottom: 80px;
}


.news-summaries.no-background {
	.archive-news-item {
		background-color: transparent;
	}
}

.map-2 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	img {
		width: 100%;
		display: block;
	}
}

.page-landing {
	.landing-top {
		.ratio(.563);
		background-color: black;
		position: relative;
		video {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
		}
		.link-bar {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			background-color: fade(@blue-menu-bottom, 80%);
			z-index: 5;
		}
		nav {
			a {
				.barlowc(19);
			}
		}
		
		.msg {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-items: center;
			padding-right: @page-side-padding;
			padding-left: @page-side-padding;
			
			.headline {
				width: @container-width;
				max-width: 100%;
				margin: auto;
				color: white;
				
				h1 {
					.barlowc(80);
					text-transform: uppercase;
					font-style: italic;
					line-height: 1em;
					margin-bottom: 0;

					strong {
						color: @red;
					}
				}
				h2 {
					.barlow(23);
					font-weight: 300;
					margin-top: 40px;
					margin-bottom: 40px;

				}
				.solid-button {
					// color: white;
					// font-weight: 600;
					// background-color: @red;
				}
				
				h1, h2, p {
					max-width: @container-width * .5;
				}
			}
		}
		.tablet({
			.link-bar {
				.container {
					display: grid;
					grid-template-columns: auto 1fr;
					padding-bottom: 20px;
					
					nav {
						align-self: end;
					}
				}

			}
		});
		.tablet-and-smaller({
			display: grid;
			grid-template-rows: auto auto auto;
			&:before {
				display: none;
			}
			header {
				grid-row: 1;
				.link-bar {
					position: relative;
					.container {
						padding-top: 20px;
						padding-bottom: 20px;

					}
				}
				.logo {
					img {
						max-width: 60%;

					}
				}
			}
			video {
				position: relative;
				height: auto;
				bottom: auto;
				width: 100%;
				grid-row: 2;
			}
			.msg {
				position: relative;
				display: block;
				grid-row: 3;
				padding: 20px;
				
				.headline {
					width: auto;
					h1, h2, p {
						max-width: 100%;
						text-align: center;
					}
				}
				h1 {
					margin-top: 0;
				}
			}
		});
		.big-phone-and-smaller({
			.msg {
				.headline {
					h1 {
						.barlowc(40);
					}

				}
			}

		});
	}
	
	
	.full-bleed-pic-divided {
		.pic {
			max-height: none;
		}
	}
	
	.news-summaries {
		display: grid;
		row-gap: 20px;
		column-gap: 20px;
		margin-top: 30px;
		margin-bottom: 30px;
		
		justify-items: center;
		
		& > * {
			max-width: 600px;
			width: 100%;
		}
		.tablet({
			grid-template-columns: repeat(2, 1fr);
		});
	}
	
	.developers {
		.ready-to-load-img.developers-welcome-picture-background();
		.full-back;
		padding-top: 50px;
		padding-bottom: 30px;
		text-align: center;
		
		h1 {
			.barlowc(70);
			color: white;
			font-style: italic;
			margin: 0;
			text-transform: uppercase;
		}
		h2 {
			.barlowc(35);
			color: white;
			font-style: italic;
			margin-top: 0;
			text-transform: uppercase;
			strong {
				color: @red;
			}
		}
	}
	
	.rochester-competes {
		display: grid;
		
		@center-width: 60px;
		
		.msg {
			h2 {
				color: @blue-dark;
				text-transform: uppercase;
				line-height: 1em;
				em {
					.barlowc(35);
					color: @blue-light;
				}
			}
			padding-bottom: 30px;
		}
		.pic {
			.full-back;
		}
		.tablet({
			grid-template-columns: minmax(@page-side-padding, 1fr) minmax(100px, @container-width * .55) @container-width * .1 minmax(100px, @container-width * .35) minmax(@page-side-padding, 1fr);
			.left {
				.full-back;
				.ready-to-load-img.competes-left-picture-background();
				grid-column: 1 / span 3;
				grid-row: 1;
			}
			.msg {
				grid-row: 1;
				grid-column: 2 ;
				padding-right: @page-side-padding;
			}
			.pic {
				grid-column: 3 / span 3;
				grid-row: 1;
				.ready-to-load-img.pool-audience-picture-background();
				background-position: left center;
				background-size: cover;
			}
		});
		.tablet-and-smaller({
			.pic {
				.full-back;
				.ready-to-load-img.pool-audience-square-picture-background();
				.pool-audience-square-picture-background-ratio;
			}

		});
	}
	.get-involved {
		.ready-to-load-img.get-involved-picture-background();
		.full-back;
		padding-top: 50px;
		padding-bottom: 30px;
		
		h1 {
			.barlowc(70);
			color: white;
			font-style: italic;
			margin: 0;
			text-transform: uppercase;
			line-height: 1em;
		}
		p {
			.barlow(20);
			color: white;
		}
		
		.container {
			display: grid;
			column-gap: 80px;
			row-gap: 20px;
			.tablet({
				grid-template-columns: 6fr 4fr;
				.right {
					align-self: end;
				}
			})
		}
	}
}

.about-facilities-2-picture-background {
	.ready-to-load-img.about-facilities-2-picture-background();
}

.by-the-numbers {
	.ready-to-load-img.volley-ball-hands-reaching-picture-background();
	.full-back;
	padding-bottom: 10px;
	padding-top: 10px;
	
	color: white;
	
	
	
	h2 {
		strong {
			color: @blue-light;
		}
	}

	&.with-news {
		.ready-to-load-img.roch-skyline-picture-background();
		background-position: top center;
		
		.archive-news-item {
			background: transparent;
			
			h3 {
				display: none;
			}
			
			a {
				color: @blue-light;
			}
		}

	}
}

.stats {
	display: grid;
	justify-content: center;
	margin-top: 30px;
	margin-bottom: 30px;
	
	
	.stat {
		border-right: solid 2px @red;
		padding-top: 14px;
		padding-bottom: 14px;
		text-align: center;
		&:last-child {
			border-right: 0;
		}
		
		.number {
			.barlowc(87);
			text-transform: uppercase;
			font-style: italic;
			color: @blue-light;
			margin: 0;
			line-height: 1em;
		}
		.label {
			.barlowc(20);
			color: white;
			text-transform: uppercase;
			font-weight: 600;
		}
	}
	.tablet({
		grid-template-columns: repeat(4, 1fr);
	});
	.tablet-and-smaller({
		grid-template-columns: repeat(2, 1fr);
		row-gap: 20px;;
		.stat:nth-child(2) {
			border-right: 0;
		}
	});
	.phone-and-smaller({
		grid-template-columns: repeat(1, 1fr);
		.stat {
			border-right: 0;
		}
	});
}

.mission {
	margin-top: 50px;
	margin-bottom: 50px;
	.container {
		display: grid;
		row-gap: 45px;
		column-gap: 45px;
		
		.tablet({
			grid-template-columns: repeat(3, 1fr);
		});
	}
	
	.box {
		.red-corner;
		background-color: @blue-menu-top;
		padding: 30px 50px;
		max-width: 400px;
		justify-self: center;
		
		h2 {
			.barlowc(44);
			font-style: italic;
			color: white;
			margin-top: 0;
			margin-bottom: 0;
			text-transform: uppercase;
			line-height: 1.1em;
		}
		p {
			.barlow(22);
			color: white;
		}
		
	}
}

.donors-donation-spotlight-inset {
	// .ready-to-load-img.donors-donation-spotlight-inset-picture-background();
	background-color: @grey-box-background;
	display: flex;
	align-items: center;
	justify-content: center;
}
.about-need-inset {
	.ready-to-load-img.about-need-inset-picture-background();
}

.tourism-news {
	margin-top: 40px;
	margin-bottom: 40px;


	.archive-news-item {
		background: transparent;
		h3 {
			display: none;
		}
	}
}

.news-content-area {
	a {
		text-decoration: underline;
		color: inherit;
	}
	img {
		max-width: 100%;
	}
}