.solid-button {
	.barlowc(20);
	display: inline-block;
	text-transform: uppercase;
	line-height: 1em;
	padding: .5em 1em;
	text-decoration: none;
	border: 0;
	
	&.red {
		color: white !important;
		font-weight: 600;
		background-color: @red;
		svg {
			fill: white;
		}
	}
	&.blue {
		color: white;
		font-weight: 600;
		background-color: @blue-light;
		
		svg {
			fill: white;
		}
	}
	
	.document-icon {
		display: inline-block;
		width: 15px;
		vertical-align: sub;
		padding-right: 12px;
		max-height: 30px;
		// margin-top: 5px;
	}
}


.full-bleed-pic-divided {
	background-color: @blue-menu-top;
	display: grid;
	.tablet({
		grid-template-columns: minmax(@page-side-padding, 1fr) minmax(100px, @container-width * .6) minmax(100px, @container-width * .4) minmax(@page-side-padding, 1fr);
		.pic {
			grid-column: span 2;

		}
	});
	.tablet-and-smaller({
		.pic {
			aspect-ratio: 2;
			&:before {
				display: none;
			}

		}
		.msg {
			padding-left: @page-side-padding;
			padding-right: @page-side-padding;
		}
	});

	.pic {
		font-size: 0;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		overflow: hidden;
		
		&:before {
			content: '\00a0';
			position: absolute;
			left: 0;
			bottom: 0;
			border-top: solid @blue-menu-top 600px;
			border-right: solid transparent 60px;
			border-bottom: 0;
			border-left: 0;
			width: 50px;
		}
	
		
	}
	
	.msg {
		color: white;
		padding-top: 70px;
		padding-bottom: 70px;
		
		h2 {
			.barlowc(44);
			font-style: italic;
			text-transform: uppercase;
			.red-dash-headline;
			
			&:first-child {
				margin-top: 0;
			}
		}
		
		p {
			.barlow(20);
			font-weight: 300;
		}
		
	}
	
	&.grey {
		background-color: transparent;
		.msg {
			h2 {
				color: @grey-body-text;
				margin-bottom: 10px;
			}
			h3 {
				color: @blue-light;
				.barlowc(35);
				font-style: italic;
				text-transform: uppercase;
				margin-bottom: 0;
				margin-top: 0;
			}
			h4 {
				.barlow(20);
				color: @blue-light;
				margin-top: 0;
			}
			p {
				color: @grey-body-text;
			}
		}
		.pic {
			max-height: 300px;
			&:before {
				border-top: solid white 600px;
				
			}
		}
	}
}

.full-bleed-pic-on-right {
	display: grid;
	column-gap: 30px;
	row-gap: 30px;
	.tablet({
		grid-template-columns: minmax(@page-side-padding, 1fr) minmax(100px, @container-width * .5) minmax(100px, @container-width * .5) minmax(@page-side-padding, 1fr);

	});

	.pic {
		font-size: 0;
		grid-column: span 2;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		overflow: hidden;
		padding: 40px;
		img {
			width: 100%;
		}
		
	
		
	}
	
	.msg {
		color: white;
		padding-top: 70px;
		padding-bottom: 70px;
		
		h2 {
			.barlowc(44);
			font-style: italic;
			text-transform: uppercase;
			color: @grey-body-text;
			
			&:first-child {
				margin-top: 0;
			}
		}
		
		p {
			color: @grey-body-text;
			.barlow(20);
			font-weight: 300;
		}
		
	}
}

.red-dash-headline {
	&:after {
		content: '\00a0';
		border-bottom: solid 8px @red;
		display: block;
		font-size: 0;
		width: 55px;
		margin-top: 10px;
		height: 0;
	}
}

.red-corner {
	position: relative;
	overflow: hidden;

	&:after {
		content: '\00a0';
		font-size: 0;
		position: absolute;
		top: -30px;
		right: 0px;
		border: solid 30px transparent;
		border-right: solid 50px @red;
	}
}

.person-full {
	background-color: @grey-box-background;
	.env, .phone {
		fill: @red;
		width: 20px;
		max-height: 20px;
	}
	
	.contact-methods {
		td {
			padding-right: 10px;
		}
	}
	
	.pic {
		.red-corner;
		background-position: center center;
		background-size: cover;
	}
	
	.msg {
		padding: 40px;
		
		h3:first-child {
			margin-top: 0;
			color: @blue-menu-bottom;
			font-style: italic;
		}
		
		a {
			text-decoration: none;
			color: @red;
		}
	}

	.big-phone({
		display: grid;
		grid-template-columns: 17fr 50fr;
		

	});
	
	
}

.expando {
	@padding-spec: 25px;
	background-color: @grey-box-background;

	.opener {
		text-transform: uppercase;
		cursor: pointer;
		padding: 15px 25px;
		position: relative;
		small {
			display: block;
			.barlow(16);
			color: @grey-body-text;
			text-transform: none;
			font-weight: 400;
		}
		
		&:before {
			content: '\00a0';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 60px;
			background-color: @grey-box-darker;
		}

		&:after {
			content: '\00a0';
			background-image: url('/img/chevron-red-down.png');
			background-position: center center;
			background-repeat: no-repeat;
			transition: transform .3s;
			border-bottom: 0;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			height: 100%;
			width: 60px;
			align-content: center;
			margin-top: 0;
		}
	}

	&.open {
		& > .opener:after {
			transform: rotate(180deg);
		}
	}

	.view {
		max-height: 0;
		overflow: hidden;
		transition: max-height .3s;

		.content {
			padding: @padding-spec;
		}
	}

	.editing-page & {
		.view {
			max-height: none;
		}
	}
}

.expando-std {
	.expando;
	background-color: transparent;
	.opener {
		.barlowc(25);
		color: @blue-light;
		margin-bottom: 0;
		padding-left: 60px;
		background-color: @grey-box-background;

		&:before {
			display: none;
		}
		&::after {
			right: auto;
			left: 0;
		}
	}
	.view {
		background-color: transparent;
	}
	
	.msg {
		padding-top: 0;
	}
	.desc {
		p:first-child {
			margin-top: 0;
		}
	}
}
.person-expando {
	.expando;
	.opener {
		.barlowc(25);
		color: @blue-dark;
		margin-bottom: 0;
	}
	.view {
		padding-left: 0;
		.content {
			padding-left: 0;
		}
	}
	.pic {
		.ratio(1);
		align-self: start;
	}
	
	.msg {
		padding-top: 0;
	}
	.desc {
		p:first-child {
			margin-top: 0;
		}
	}
}

.group-names {
	font-size: 0;
	.person-name {
		display: inline-block;
		width: @container-width / 5;
		color: @blue-light;
		.barlowc(30);
		font-weight: 600;
		font-style: italic;
	}
}

.meeting-minutes-link {
	display: block;
	text-decoration: none;
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: solid white 1px;
	color: @blue-dark;

	svg {
		fill: @blue-dark;
		width: 15px;
		max-height: 25px;
		padding-right: 20px;
		vertical-align: middle;
	}
}

.donor-thirds {
	display: grid;
	column-gap: 40px;
	row-gap: 40px;
	margin-bottom: 50px;
	
	.tablet({
		grid-template-columns: minmax(auto, 2fr) minmax(300px, 1fr);
		
	});
}

.top-news-item {
	display: grid;
	row-gap: 30px;
	background-color: @grey-box-background;
	margin-bottom: 30px;

	h2, h3 {
		.barlowc(44);
		font-style: italic;
		text-transform: uppercase;
		margin: 0;
	}
	h3 {
		color: @blue-light
	}
	.msg {
		padding: 40px;
	}
	
	.tablet({
		grid-template-columns: 2fr 1fr;
		.msg {
			padding-right: 70px;
		}
	});
	
	.pics {
		img {
			width: 100%;
			display: block;
			margin: 0 0 0 auto;
			max-width: 300px;
		}
		img + img {
			margin-top: 20px;
		}
	}
}


.archive-news-item {
	display: grid;
	row-gap: 30px;
	background-color: @grey-box-background;
	margin-bottom: 30px;

	h2, h3 {
		margin: 0;
	}
	h2 {
		.barlowc(44);
		text-transform: uppercase;
		font-style: italic;
		margin-top: 0;
		margin-bottom: 10px;
		&:after {
			display: none !important;
		}
	}
	h3 {
		.barlowc(20);
		color: @blue-light
	}
	.msg {
		padding: 0 40px 40px 40px;
	}
	.read-more {
		color: @red;
	}
	
	.tablet({
		grid-template-columns: 1fr 3fr;
	});
	
	.pics {
		img {
			width: 100%;
			display: block;
			margin: 0 auto;
			max-width: 300px;
		}
		img + img {
			margin-top: 20px;
		}
	}
	.page-looking-back & {
		.tablet({
			grid-template-columns: 3fr 1fr;
			.msg {
				padding-top: 30px;
				grid-column: 1;
				grid-row: 1;
			}
			.pics {
				grid-column: 2;
				grid-row: 1;
			}
		});
	}
}

.padded-img {
	padding: 30px;
	img {
		width: 100%;
		max-width: 350px;
	}
}

.full-headline-ad {
	position: relative;
	background-color: @blue-dark;
	padding-top: 30px;
	padding-bottom: 30px;
	
	&:before {
		content: '\00a0';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		height: 10px;
		background-color: @red;
		width: 180px;
		font-size: 0;
		
	}
	
	.container {
		text-align: center;
	}
	
	h2 {

		.barlowc(31);
		color: white;
		text-transform: uppercase;
		font-style: italic;
		
		strong {
			color: @blue-light;
		}
	}
}

.homepage-news-item {
	.pic {
		.ratio(.5);
		.full-back;
	}
	
	.msg {
		background-color: @grey-box-background;
		padding: 22px;
		h2 {
			.barlowc(24);
			font-style: italic;
			text-transform: uppercase;
			margin-top: 0;
		}
		p {
			.barlow(20);
		}
	}
}

.two-organizations {
	h2 {
		strong {
			color: @red;
		}
	}
	.organizations {
		display: grid;
		column-gap: 50px;
		row-gap: 60px;
		
		.tablet({
			grid-template-columns: auto 1fr;
			.desc {
				align-self: center;
			}
		});
	}
	
	.organization {
		display: contents;
		
		.desc {
			.barlow(20);
			line-height: 1.2em;
			h3 {
				.barlow(26);
				display: inline;
				margin-top: 0;
				margin-bottom: 0;
			}
			p {
				display: inline;
			}
			a {
				font-weight: 600;
				color: inherit;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}






